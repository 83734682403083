<template>
	<div
		v-if="count"
		class="mini-cart-meal"
	>
		<div class="mini-cart-meal__top">

			<img
				class="mini-cart-meal__img"
				:src="item?.preview_picture?.thumb"
				alt="Product"
			/>

			<div
				class="mini-cart-meal__name"
				v-html="item?.name"
			/>

			<img
				src="@/assets/img/close.svg"
				@click.stop="!isGift && changeCartItem(0, meal?.cart_id)"
				class="mini-cart-meal__remove-item"
				alt="Remove"
			/>

		</div>

		<div class="mini-cart-meal__bottom">
			<Counter
				:value="count"
				:canChange="!isGift"
				:checkCounterBetweenChange="checkCartBeforeAddProduct"
				:mini="true"
				@change="changeCartItem($event, meal?.cart_id)"
			/>
			<div class="mini-cart-meal__price-for-one">
				{{ isGift ? 0 : item?.price }} ₽/шт
			</div>
			<div class="mini-cart-meal__price">
				<svg
					v-if="isGift"
					fill="transparent"
					width="24"
					height="24"
				>
					<use xlink:href="#cart-row__gift"></use>
				</svg>
				<div class="mini-cart-meal__rub">{{ priceWithSpace }} ₽</div>
			</div>
		</div>
	</div>
</template>

<script>
import Counter from '../ui/Counter.vue'
import {addThousandSeparator} from '@/helpers';
import {useChangeCartItem} from "@/composables";
import {mapGetters} from "vuex";

export default {
	name: 'MiniCartMeal',
	setup()
	{
		const {checkCartBeforeAddProduct, changeCartItem, initProduct, count} = useChangeCartItem();

		return {checkCartBeforeAddProduct, changeCartItem, initProduct, count};
	},
	components: {Counter},
	props: {
		item: Object,
		meal: Object,
		productCount: Number,
		isGift:
			{
				type: Boolean,
				default: false
			}
	},
	computed: {
		...mapGetters({
			currentBasket: "getCurrentBasket",
		}),
		priceWithSpace()
		{
			if (this.isGift) return 0;

			const priceForOne = this.item?.price || 0;
			const price = priceForOne * this.count;

			return addThousandSeparator(price);
		},
	},
	watch:
		{
			productCount()
			{
				if (this.productCount) this.initProduct({...this.item, count: this.productCount});
			}
		},
	mounted()
	{
		this.initProduct({...this.item, count: this.productCount});
	}
}
</script>

<style lang="scss">
.mini-cart-meal__remove-item
{
	height: 24px;
	cursor: pointer;
}

.mini-cart-meal__top
{
	display: flex;
	margin-bottom: 8px;
}

.mini-cart-meal__name
{
	width: 100%;
	font-size: 16px;
	line-height: 20px;
	font-family: $mainFontNew;
}

.mini-cart-meal__img
{
	border-radius: 24px;
	object-fit: cover;
	width: 40px;
	height: 40px;
	min-width: 40px;
	margin-right: 12px;
}

.mini-cart-meal__remove
{
	min-width: 20px;
	cursor: pointer;
}

.mini-cart-meal__bottom
{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mini-cart-meal__counter
{width: 102px;}

.mini-cart-meal__price-for-one
{
	background-color: $greyBackground;
	border-radius: 2px;
	padding: 4px 6px;
	font-size: 14px;
	line-height: 20px;
	font-family: $mainFontBoldNew;
	color: $textColor;
}

.mini-cart-meal__price
{
	display: flex;
	align-items: center;
	gap: 6px;
}

.mini-cart-meal__rub
{
	font-size: 20px;
	font-family: $mainFontBoldNew;
	letter-spacing: -1px;
}
</style>
